import React, { useContext } from 'react';
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import { DataContext } from '../layout/Default';

function Header() {
  const { sessionData } = useContext(DataContext);

  const title = sessionData?.pageData?.headerText || '';
  const salesFacing = sessionData?.pageData?.salesFacing || false;

  const getTitle = () => {
    const title = sessionData?.pageData?.headerText || '';
    const titleArr = title.split(' ');
    const index = title.indexOf(titleArr[Math.round(titleArr.length / 2)]);
    if (index > 0) {
      return (
        <>
          {title.substring(0, index)}
          <span className="text-success">
            {title.substring(index, title.length)}
          </span>
        </>
      );
    }

    return title;
  };

  const getLogoSrc = () => {
    if(sessionData?.store?.whitelabel) {
      return `/media/${sessionData.store.whitelabel}-header-logo.svg`;
    }

    return '/media/header-logo-icon.svg';
  };

  return (
    <Navbar
      sticky="top"
      className="defaultHeader"
      expand={false}
      bg={salesFacing && 'blue-100'}
    >
      <Container>
        <div className="navbar-row">
          <div className="navbar-header">
            {title && (
              <div className="title">
                <h1 className="title-text">{getTitle()}</h1>
              </div>
            )}

            {salesFacing && (
              <div className="sales-flag">
                <span>Sales Associate</span>
              </div>
            )}
          </div>
          <div className="navbar-brand" tabIndex="-1">
            <img
              src={getLogoSrc()}
              alt="logo icon"
              className="img-responsive"
            />
          </div>
        </div>
      </Container>
    </Navbar>
  );
}

Header.propTypes = {};

export default Header;
